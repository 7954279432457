
import Rounded2 from '../Components/Rounded2'





function Achievment(){
    return(
  
        <>

        <div className="home left-0 right-0 bottom-0 ">
        <Rounded2 />
        </div>


        </>
    );
}

export default Achievment;