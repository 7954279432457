import Profile2 from "../Components/Profile2";
import Textjadwal from "../Components/Textjadwal";
import Navbar2 from "../Components/Navbar2";


function Anggota(){
    return(

        <>
        
        <div className="home left-0 right-0 bottom-0 ">
        <Textjadwal />
        <Profile2 />
        </div>
      
        </>
    );
}

export default Anggota;