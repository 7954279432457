import Error from '../Components/Error'

function NoPage(){
    return(

        <>
        <Error />
        </>

    );
}

export default NoPage;