
export default function Button(){
    return(

        <button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center "><a href="https://docs.google.com/forms/d/e/1FAIpQLSeFK6BVlJX3EoSKM5pWUEAzKA3koZ3B_siLITTo5NP9aQ7Fcg/viewform" target="_blank">
            Daftar
        </a></button>


    );

}