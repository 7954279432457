export default function Heading(){
    return(

   <div>
        <h1 className="pt-10 mb-4 font-extrabold tracking-tight text-center text-gray-700 lg:text-5xl ">TAEKWONDO POLITEKNIK NEGERI JAKARTA</h1>
        <p className="mb-8 text-lg font-normal text-gray-450 lg:text-l  text-center">Kita bukan teman, kita bukan sahabat, tapi kita keluarga, #GoFIGHTWIN .</p>
        
    </div> 

    );
}