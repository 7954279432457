export default function Textjadwal(){
    return(

        <>
        <div className="py-[60px]">
        <h1 className=" bg-[#FBFFDC] h-[60px]  xl:w-[80%] sm:w-[50px] rounded-full text-center flex  justify-center items-center mx-auto font-semibold shadow-xl text-gray-700 lg:text-3xl">Anggota Taekwondo PNJ 2023/2024</h1>
        </div>
        </>

    );
}